import { Routes, Route } from 'react-router-dom';
import './App.css';
import { Home } from './pages/Home';
import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';
import { Cashout } from './pages/Cashout';
import { Datdrop } from './pages/Datdrop';
import { Competition } from './components/Competition';
import { NotFound } from './pages/NotFound';
import { Empiredrop } from './pages/Empiredrop';
import { NewTiers } from './pages/NewTiers';
import { lazy, Suspense } from 'react';
import Loader from './components/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './context/AuthContext';

const EmpiredropDeposits = lazy(() => import('./pages/EmpiredropDeposits'));
const CS2BigBattles = lazy(() => import('./pages/CS2BigBattles'));
const Profile = lazy(() => import('./pages/Profile'));
const Cases = lazy(() => import('./pages/Cases'));
const CaseOpen = lazy(() => import('./pages/CaseOpen'));
const Admin = lazy(() => import('./pages/Admin/Admin'));
const AdminBalanceUpdate = lazy(() => import('./pages/Admin/AdminBalanceUpdate'));
const AdminReview = lazy(() => import('./pages/Admin/AdminReview'));

function App() {
  const { isAuthenticated, isAdmin } = useAuth();
  return (
    <>
      <Navbar />
      <div className="App">
        <video autoPlay playsInline muted loop id="bg-video">
          <source src="/STATIC_SHOT.mp4" type="video/mp4" />
        </video>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tiers" element={<NewTiers />} />
          <Route path="/cashout" element={<Cashout />} />
          <Route path="/datdrop" element={<Datdrop />} />
          <Route path="/cs2big/battles" element={<Suspense fallback={<Loader />}><CS2BigBattles /></Suspense>} />
          <Route path="/empiredrop" element={<Empiredrop />} />
          <Route path="/empiredrop/competition" element={<Competition competitionName="empiredrop-comp" />} />
          <Route path="/empiredrop/deposits" element={<Suspense fallback={<Loader />}><EmpiredropDeposits /></Suspense>} />
          <Route path="/datdrop100" element={<Competition competitionName="datdrop-comp100" />} />
          <Route path="/datdrop500" element={<Competition competitionName="datdrop-comp500" />} />


          {isAuthenticated && <Route path="/cases" element={<Suspense fallback={<Loader />}><Cases /></Suspense>} />}
          {isAuthenticated && <Route path="/cases/:id" element={<Suspense fallback={<Loader />}><CaseOpen /></Suspense>} />}
          {isAuthenticated && <Route path="/profile" element={<Suspense fallback={<Loader />}><Profile /></Suspense>} />}
          {isAdmin && <Route path="/admin" element={<Suspense fallback={<Loader />}><Admin /></Suspense>} />}
          {isAdmin && <Route path="/admin/balance" element={<Suspense fallback={<Loader />}><AdminBalanceUpdate /></Suspense>} />}
          {isAdmin && <Route path="/admin/review" element={<Suspense fallback={<Loader />}><AdminReview /></Suspense>} />}
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastClassName="bg-black bg-opacity-90 text-white"
          bodyClassName="text-sm"
          progressClassName="bg-black"
          toastStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.9)', color: 'white' }}
        />
      </div>
      <Footer />
    </>
  );
}

export default App;
